import {
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from "@hello-pangea/dnd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import { Button, EditableText, Icon } from "@smart/itops-sb-design-system-dom";

import {
  getDroppablePlaceholderClassNames,
  getGroupCardClassNames,
  getGroupFieldContainerClassNames,
  getGroupFieldListClassNames,
  groupStyles,
} from "./class-names";
import { groupFieldsDroppableIdPrefix } from "../../constants";
import { ConditionsButton } from "../../edit-view/condition/conditions-button";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
  QuestionEditMode,
} from "../../types";
import { Field } from "../field";
import { Separator } from "../separator";

type GroupCardProps = {
  group: GqlGroupValues;
  groupFields?: GqlFieldValues[] | null;
  isDragging?: boolean;
  onDeleteGroup: () => Promise<void>;
  onUpdateGroup: ({
    label,
    description,
    repeatable,
  }: {
    label?: string;
    description?: string;
    repeatable?: boolean;
  }) => Promise<void>;
  mode?: QuestionEditMode;
  editing?: boolean;
  loading?: boolean;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  updatingItems: Record<string, boolean | undefined>;
  editingItemUri?: string;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  conditionActions: ConditionActions;
  onFieldTypeChange: OnFieldTypeChange;
  isDropAnimating: boolean;
  isValidSection: boolean;
  excludingFieldTemplates: FieldTemplate[];
};

const DroppablePlaceHolder = ({
  droppableId,
  draggingExisting,
}: {
  droppableId: string;
  draggingExisting?: boolean;
}) => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        className={twMerge(
          getDroppablePlaceholderClassNames({
            isDraggingOver: snapshot.isDraggingOver || draggingExisting,
          }),
        )}
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        <div className="text-ui px-4 text-neutral-500">
          Drag and drop &apos;Components&apos; here
        </div>
      </div>
    )}
  </Droppable>
);

export const GroupCard = ({
  mode,
  group,
  onDeleteGroup,
  editing,
  onUpdateGroup,
  groupFields,
  updatingItems,
  onUpdateField,
  onDeleteField,
  editingItemUri,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  isDragging,
  dragHandleProps,
  conditionActions,
  onFieldTypeChange,
  isDropAnimating,
  isValidSection,
  excludingFieldTemplates,
}: GroupCardProps) => {
  const {
    uri: groupUri,
    label: groupTitle = "",
    description,
    repeatable,
    field: groupMatterField,
    layout: groupLayout,
  } = group;
  const [isEditingTitle, setIsEditingTitle] = useState(editing);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] =
    useState(!!description);
  const [editedGroupTitle, setEditedGroupTitle] = useState(
    groupTitle || fieldFallbackLabel.group,
  );
  const [editedDescription, setEditedDescription] = useState<
    string | undefined
  >(description || "");
  const [isShrinking, setIsShrinking] = useState(false);
  useEffect(() => {
    setIsShrinking(!!isDragging);
  }, [isDragging]);

  useEffect(() => {
    setIsEditingTitle(editing);
  }, [editing]);

  const isFieldMovingInSection = (draggingOverGroup: boolean) =>
    mode === "reordering" && !draggingOverGroup;

  return (
    <div
      className={getGroupCardClassNames({
        current: !!isEditingTitle || isEditingDescription || !!isDragging,
      })}
    >
      <div className={groupStyles.header}>
        <div className={groupStyles.title}>
          <EditableText
            text={editedGroupTitle}
            onChange={setEditedGroupTitle}
            onComplete={() => onUpdateGroup({ label: editedGroupTitle })}
            editing={isEditingTitle}
            onEditing={setIsEditingTitle}
            fontSize="paragraph"
            fontWeight="semibold"
          />
        </div>
        <div className={groupStyles.groupActions}>
          {conditionActions.checkHasConditions(group.uri) && (
            <ConditionsButton
              dataTestId="group-conditions-button"
              numberOfConditions={conditionActions.getNumberOfConditions(
                group.uri,
              )}
              onClick={() => conditionActions.setEditingCondition(group)}
              size="small"
            />
          )}
          <Button
            dataTestId="repeat-group-button"
            leftIcon="regularArrowsRepeat"
            variant="secondarySubtle"
            onClick={async () => onUpdateGroup({ repeatable: !repeatable })}
            isPressed={!!repeatable}
            size="small"
          />
          <Button
            dataTestId="group-description-button"
            leftIcon="regularText"
            variant="secondarySubtle"
            onClick={async () => {
              setIsDescriptionVisible(!isDescriptionVisible);
              if (isDescriptionVisible) {
                setEditedDescription("");
                await onUpdateGroup({ description: "" });
              }
            }}
            isPressed={isDescriptionVisible}
            size="small"
          />
          <Button
            dataTestId="delete-group-button"
            leftIcon="regularTrashCan"
            variant="secondarySubtle"
            onClick={onDeleteGroup}
            size="small"
          />
        </div>
      </div>
      {isDescriptionVisible && (
        <div className="pl-[5rem]">
          <EditableText
            editing={isEditingDescription}
            text={editedDescription}
            onChange={setEditedDescription}
            onComplete={async () => {
              setIsEditingDescription(false);
              await onUpdateGroup({ description: editedDescription });
            }}
            placeholder="Add descriptive text here, or leave blank if not needed"
            fontSize="paragraph"
          />
        </div>
      )}
      <div
        className={clsx(
          groupStyles.content,
          repeatable ? "pb-[3.2rem]" : "pb-8",
        )}
      >
        <div
          className={groupStyles.dragHandle}
          {...dragHandleProps}
          tabIndex={0}
          role="button"
          onMouseDown={() => setIsShrinking(true)}
          onMouseUp={() => setIsShrinking(false)}
        >
          <Icon name="solidGripDotsVertical" className="w-8 h-8 font-bold" />
        </div>
        <div className={groupStyles.main}>
          <Droppable
            droppableId={`${groupFieldsDroppableIdPrefix}-${groupUri}`}
            type="GROUP"
          >
            {(provided, snapshot) => (
              <div
                className={getGroupFieldListClassNames({
                  shrinking:
                    isShrinking ||
                    isFieldMovingInSection(snapshot.isDraggingOver),
                })}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!groupFields?.length && (
                  <DroppablePlaceHolder
                    droppableId={`${groupUri}|${groupFields?.length || 0}`}
                    draggingExisting={snapshot.isDraggingOver}
                  />
                )}
                {!!groupFields?.length && (
                  <Separator
                    index={0}
                    groupUri={groupUri}
                    isFieldReordering={mode === "reordering"}
                  />
                )}
                {groupFields?.length ? (
                  groupFields.map((groupField, i) => (
                    <div
                      className={twMerge(
                        getGroupFieldContainerClassNames({
                          shrinking:
                            isShrinking ||
                            isFieldMovingInSection(snapshot.isDraggingOver),
                          index: i,
                        }),
                      )}
                      key={groupField.uri}
                      data-testid="group-field-container"
                    >
                      <Draggable draggableId={groupField.uri} index={i}>
                        {(draggableProvided, fieldSnapshot) => (
                          <Field
                            index={i}
                            item={groupField}
                            loading={updatingItems[groupField.uri]}
                            shrinking={
                              isShrinking ||
                              isFieldMovingInSection(snapshot.isDraggingOver)
                            }
                            mode={mode}
                            onUpdateField={onUpdateField}
                            onDeleteField={onDeleteField}
                            draggableProvided={draggableProvided}
                            isDragging={fieldSnapshot.isDragging}
                            isEditing={groupField.uri === editingItemUri}
                            matterLayouts={matterLayouts}
                            matterTypes={matterTypes}
                            loadMatterFields={loadMatterFields}
                            conditionActions={conditionActions}
                            groupLayout={groupLayout}
                            groupMatterField={groupMatterField}
                            onFieldTypeChange={onFieldTypeChange}
                            isDropAnimating={isDropAnimating}
                            isValidSection={isValidSection}
                            ignoreDraggableStyle={isDragging}
                            excludingFieldTemplates={excludingFieldTemplates}
                          />
                        )}
                      </Draggable>
                    </div>
                  ))
                ) : (
                  <div className="group-field-spacer" />
                )}
                {!!groupFields?.length && provided.placeholder}
                {(isShrinking ||
                  isFieldMovingInSection(snapshot.isDraggingOver)) && (
                  <div className={groupStyles.shrinkShade} />
                )}
              </div>
            )}
          </Droppable>
          {!!repeatable && (
            <div className="relative max-w-[80rem] w-full m-auto">
              <div className="absolute bottom-[-3.6rem]">
                <Button
                  leftIcon="regularPlus"
                  text="Add"
                  variant="secondaryDefault"
                  size="small"
                  disabled
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
