import { useState } from "react";

import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { FieldNS, FieldType, GroupNS } from "@smart/bridge-types-basic";
import {
  DeepPartial,
  localTimeZoneCode,
  rankBetween,
  removeEmptyValues,
  removeKeys,
  removeKeysRecursively,
} from "@smart/itops-utils-basic";
import {
  useDeleteField,
  useDeleteGroup,
  useDeleteSection,
  useUpdateAIUserFeedback,
  useUpdateField,
  useUpdateFieldOrder,
  useUpdateGroup,
  useUpdateGroupOrder,
  useUpdateGroupWithFields,
  useUpdateSection,
} from "@smart/manage-gql-client-dom";

import { fieldComponentNames } from "../constants";
import {
  CreateMappedFields,
  EditableSection,
  UpdatedSection,
  GqlField,
  GqlForm,
  GqlGroup,
  GqlFieldValues,
  GqlGroupValues,
} from "../types";
import { getDefaultAvailability } from "../utils";

const getInitialFieldValues = (
  fieldType: FieldType,
): Partial<GqlFieldValues> | undefined => {
  switch (fieldType) {
    case "appointment":
      return {
        label: "Appointment",
        duration: 15,
        availability:
          getDefaultAvailability() as GqlFieldValues["availability"],
        timezone: localTimeZoneCode,
        meetingType: "inPerson",
      };
    default:
      return undefined;
  }
};

export const useUpdateData = ({
  formUri,
  form,
  sectionFields,
  sectionGroups,
  createForm,
  currentSectionUri,
  setCurrentSectionUri,
  shouldCreateInitialSection,
}: {
  formUri: string;
  form?: GqlForm | null;
  sectionFields: GqlField[] | undefined;
  sectionGroups: GqlGroup[] | undefined;
  createForm: () => Promise<void>;
  currentSectionUri: string;
  setCurrentSectionUri: (uri: string) => void;
  shouldCreateInitialSection: boolean;
}) => {
  const [updateSectionMutation] = useUpdateSection();
  const [deleteSectionMutation] = useDeleteSection();
  const [updateGroupMutation] = useUpdateGroup();
  const [updateGroupOrder] = useUpdateGroupOrder(false);
  const [updateGroupWithFieldsMutation] = useUpdateGroupWithFields();
  const [deleteGroup] = useDeleteGroup();
  const [updateFieldMutation] = useUpdateField();
  const [updateFieldOrder] = useUpdateFieldOrder(false);
  const [deleteField] = useDeleteField();
  const [updateAIUserFeedbackMutation] = useUpdateAIUserFeedback();

  const [newItemUri, setNewItemUri] = useState<string>();
  const [updatingItems, setUpdatingItems] = useState<
    Record<string, boolean | undefined>
  >({});
  const setIsUpdating = (uri: string, value: boolean) =>
    setUpdatingItems((prev) => ({ ...prev, [uri]: value }));

  const updateAIUserFeedback: typeof updateAIUserFeedbackMutation = async (
    ...args
  ) => {
    const uri = args?.[0]?.variables?.uri;
    if (uri) setIsUpdating(uri, true);
    const result = await updateAIUserFeedbackMutation(...args);
    if (uri) setIsUpdating(uri, false);
    return result;
  };

  const updateSection: typeof updateSectionMutation = async (...args) => {
    const uri = args?.[0]?.variables?.uri;
    if (uri) setIsUpdating(uri, true);
    const result = await updateSectionMutation(...args);
    if (uri) setIsUpdating(uri, false);
    return result;
  };

  const updateGroup: typeof updateGroupMutation = async (...args) => {
    const uri = args?.[0]?.variables?.uri;
    if (uri) setIsUpdating(uri, true);
    const result = await updateGroupMutation(...args);
    if (uri) setIsUpdating(uri, false);
    return result;
  };

  const updateField: typeof updateFieldMutation = async (...args) => {
    const uri = args?.[0]?.variables?.uri;
    if (uri) setIsUpdating(uri, true);
    const result = await updateFieldMutation(...args);
    if (uri) setIsUpdating(uri, false);
    return result;
  };

  const updateGroupWithFields: typeof updateGroupWithFieldsMutation = async (
    ...args
  ) => {
    const uri = args?.[0]?.variables?.uri;
    if (uri) setIsUpdating(uri, true);
    const result = await updateGroupWithFieldsMutation(...args);
    if (uri) setIsUpdating(uri, false);
    return result;
  };

  const createFormAndSection = async () => {
    if (!form) await createForm();
    if (shouldCreateInitialSection) {
      await updateSection({
        variables: {
          uri: currentSectionUri,
          formUri,
          order: rankBetween({}),
          fields: {
            title: "Section",
          },
        },
      });
    }
  };

  const mergeUpdateField = ({
    current,
    incoming,
    shouldRemoveMappedLayout,
  }: {
    current: GqlFieldValues;
    incoming: DeepPartial<GqlFieldValues> & { withGroupUpdate?: boolean };
    shouldRemoveMappedLayout?: boolean;
  }) => {
    const mergedFields = {} as GqlFieldValues & { withGroupUpdate?: boolean };
    Object.assign(mergedFields, current, incoming);

    return updateField({
      variables: {
        uri: mergedFields.uri,
        formUri,
        sectionUri: mergedFields.sectionUri,
        groupUri: mergedFields.groupUri || undefined,
        order: mergedFields.order,
        withGroupUpdate: mergedFields.withGroupUpdate,
        fields: {
          label: mergedFields.label,
          description: mergedFields.description ?? undefined,
          hint: mergedFields.hint,
          type: mergedFields.type,
          mandatory: mergedFields.mandatory || false,
          options: mergedFields.options
            .filter(Boolean)
            .map((option) => removeKeys(option, ["__typename"])),
          allowCustomResponse: mergedFields.allowCustomResponse || undefined,
          layout:
            shouldRemoveMappedLayout || !mergedFields.layout
              ? undefined
              : (removeEmptyValues(
                  removeKeys(mergedFields.layout, [
                    "__typename",
                    "displayName",
                  ]),
                ) as GqlField["values"]["layout"]),
          field:
            shouldRemoveMappedLayout || !mergedFields.field
              ? undefined
              : {
                  ...removeKeys(mergedFields.field, [
                    "__typename",
                    "displayName",
                    "details",
                  ]),
                  possibleValues:
                    mergedFields.field.possibleValues || undefined,
                },
          links: mergedFields.links
            ? mergedFields.links.map((f) => removeKeys(f, ["__typename"]))
            : undefined,
          allowCopyFromFieldUri:
            mergedFields.allowCopyFromFieldUri || undefined,
          availableStaffIds:
            mergedFields.availableStaffIds?.filter(Boolean) || undefined,
          duration: mergedFields.duration || undefined,
          availability: mergedFields.availability
            ? removeKeysRecursively(mergedFields.availability.filter(Boolean), [
                "__typename",
              ])
            : undefined,
          timezone: mergedFields.timezone || undefined,
          bufferTime: mergedFields.bufferTime || undefined,
          minimumNotice: mergedFields.minimumNotice || undefined,
          meetingType: mergedFields.meetingType || undefined,
          payment: mergedFields.payment
            ? {
                ...removeKeys(mergedFields.payment, ["__typename"]),
                bankAccount: mergedFields.payment.bankAccount
                  ? removeKeys(mergedFields.payment.bankAccount, ["__typename"])
                  : undefined,
              }
            : undefined,
        },
      },
    });
  };

  const mergeUpdateGroup = ({
    current,
    incoming,
  }: {
    current: GqlGroupValues;
    incoming: DeepPartial<GqlGroupValues>;
  }) => {
    const mergedGroup = {} as GqlGroupValues;
    Object.assign(mergedGroup, current, incoming);

    return updateGroup({
      variables: {
        uri: mergedGroup.uri,
        formUri: mergedGroup.formUri,
        sectionUri: mergedGroup.sectionUri,
        order: mergedGroup.order,
        fields: {
          label: mergedGroup.label || undefined,
          description: mergedGroup.description || undefined,
          hint: mergedGroup.hint,
          type: mergedGroup.type,
          field: mergedGroup.field
            ? {
                ...removeKeys(mergedGroup.field, [
                  "__typename",
                  "displayName",
                  "details",
                ]),
              }
            : undefined,
          layout: mergedGroup.layout
            ? {
                ...removeKeys(mergedGroup.layout, [
                  "__typename",
                  "displayName",
                ]),
                parentId: mergedGroup.layout.parentId || undefined,
                parentName: mergedGroup.layout.parentName || undefined,
                parentProviderId:
                  mergedGroup.layout.parentProviderId || undefined,
              }
            : undefined,
          links: mergedGroup.links
            ? mergedGroup.links.map((link) => removeKeys(link, ["__typename"]))
            : undefined,
          allowedRepeatable: mergedGroup.allowedRepeatable || undefined,
          maxRepeat: mergedGroup.maxRepeat || undefined,
          minRepeat: mergedGroup.minRepeat || undefined,
          repeatable: mergedGroup.repeatable || undefined,
          templateType: mergedGroup.templateType || undefined,
        },
      },
    });
  };

  const addMappedFields: CreateMappedFields = async ({
    newFields,
    beforePosition,
    afterPosition,
    destinationGroupUri,
    group,
  }) => {
    await createFormAndSection();

    if (group?.layout && group?.field) {
      let fieldOrderAfter: string | undefined;

      // Group doesn't exist, create group and fields
      if (!destinationGroupUri) {
        await updateGroupWithFields({
          variables: {
            uri: GroupNS.generateUri(),
            formUri,
            sectionUri: currentSectionUri,
            fields: {
              label: group.label,
              hint: "",
              type: group.type,
              layout: group.layout,
              field: group.field,
              fields: newFields.map((newField) => {
                fieldOrderAfter = rankBetween({ after: fieldOrderAfter });

                return {
                  uri: newField.uri,
                  type: newField.type,
                  label: newField.label || "",
                  hint: "",
                  options: newField.options || [],
                  mandatory: false,
                  allowCustomResponse: newField.allowCustomResponse,
                  layout: newField.layout,
                  field: newField.field,
                  order: fieldOrderAfter,
                };
              }),
            },
            order: rankBetween({
              after: afterPosition,
              before: beforePosition,
            }),
          },
        });

        return;
      }

      const existingGroup = sectionGroups?.find(
        (g) => g.uri === destinationGroupUri,
      );
      if (
        existingGroup &&
        group.type === "layoutContact" &&
        existingGroup.values.type !== "layoutContact"
      ) {
        const mappedFieldsInGroup =
          sectionFields?.filter(
            (f) =>
              f.values.groupUri === destinationGroupUri &&
              f.values.layout &&
              f.values.field,
          ) || [];
        if (mappedFieldsInGroup.length > 0) {
          console.error(
            "Group has to be a LayoutContact group or has no mapped fields!",
          );
          return;
        }

        await updateGroup({
          variables: {
            uri: destinationGroupUri,
            formUri,
            sectionUri: currentSectionUri,
            fields: {
              label: existingGroup.values.label,
              hint: existingGroup.values.hint,
              type: group.type,
              layout: group.layout,
              field: group.field,
            },
            order: existingGroup.values.order,
          },
        });
      }
    }

    let orderAfter = afterPosition;

    await Promise.all(
      newFields.map((newField) => {
        const order = rankBetween({
          after: orderAfter,
          before: beforePosition,
        });

        const promise = updateField({
          variables: {
            formUri,
            groupUri: destinationGroupUri || undefined,
            sectionUri: currentSectionUri,
            uri: newField.uri,
            order,
            fields: {
              type: newField.type,
              label: newField.label || "",
              hint: "",
              options: newField.options || [],
              mandatory: false,
              allowCustomResponse: newField.allowCustomResponse,
              layout: newField.layout,
              field: newField.field,
            },
          },
        });
        orderAfter = order;
        return promise;
      }),
    );
  };

  const addGroup = async (order: string) => {
    const uri = GroupNS.generateUri();
    try {
      await createFormAndSection();

      setIsUpdating(uri, true);
      await updateGroup({
        variables: {
          uri,
          formUri,
          sectionUri: currentSectionUri,
          order,
          fields: {
            label: "Field Group",
            hint: "",
            type: "custom",
            allowedRepeatable: true,
          },
        },
      });
      setNewItemUri(uri);
    } catch (error) {
      console.error("Error adding new group: ", error);
    } finally {
      setIsUpdating(uri, false);
    }
  };

  const addField = async ({
    type,
    order,
    groupUri,
  }: {
    type: FieldComponentType;
    order: string;
    groupUri?: string;
  }) => {
    if (!sectionFields || type === "mapped" || type === "group") return;

    const uri = FieldNS.generateUri();
    try {
      await createFormAndSection();
      setIsUpdating(uri, true);
      const initial = getInitialFieldValues(type);

      await updateField({
        variables: {
          uri,
          formUri,
          groupUri: groupUri || undefined,
          sectionUri: currentSectionUri,
          order,
          fields: {
            label: initial?.label || fieldComponentNames[type],
            type,
            hint: "",
            options: [],
            mandatory: false,
            ...initial,
          },
        },
      });
      setNewItemUri(uri);
    } catch (error) {
      console.error("Error adding new field: ", error);
    } finally {
      setIsUpdating(uri, false);
    }
  };

  const changeGroupOrder = async (
    groupUri: string | undefined,
    order: string,
  ) => {
    if (!groupUri) return;

    try {
      setIsUpdating(groupUri, true);
      await updateGroupOrder({
        variables: {
          uri: groupUri,
          formUri,
          sectionUri: currentSectionUri,
          order,
        },
      });
    } catch (error) {
      console.error("Error updating field order: ", error);
    } finally {
      setIsUpdating(groupUri, false);
    }
  };

  const changeFieldOrder = async (
    fieldUri: string | undefined,
    order: string,
  ) => {
    if (!fieldUri) return;

    try {
      setIsUpdating(fieldUri, true);
      await updateFieldOrder({
        variables: {
          uri: fieldUri,
          formUri,
          sectionUri: currentSectionUri,
          order,
        },
      });
    } catch (error) {
      console.error("Error updating field order: ", error);
    } finally {
      setIsUpdating(fieldUri, false);
    }
  };

  const updateFormSection = async (
    section: EditableSection,
    values: { title: string },
  ) => {
    if (!form) await createForm();

    setIsUpdating(section.uri, true);
    await updateSection({
      variables: {
        uri: section.uri,
        formUri,
        order: section.values?.order || rankBetween({}),
        fields: {
          title: values.title,
          description: section.values?.description || undefined,
          links: section.values?.links
            ? section.values?.links.map((link) => ({
                ...removeKeys(link, ["__typename"]),
              }))
            : undefined,
        },
      },
    });
    setIsUpdating(section.uri, false);
  };

  const updateAllSections = async (sections: UpdatedSection[]) => {
    if (!form) await createForm();

    await Promise.allSettled(
      sections.map((section) => {
        if (section.deleted) {
          if (section.created) return undefined;

          return deleteSectionMutation({
            variables: { formUri, uri: section.uri },
          });
        }
        if (section.updated) {
          if (section.created) setCurrentSectionUri(section.uri);

          return updateSection({
            variables: {
              formUri,
              uri: section.uri,
              fields: {
                title: section.title,
                description: section.description || undefined,
                links: section.links || undefined,
              },
              order: section.order,
            },
          });
        }
        return Promise.resolve();
      }),
    );
  };

  const updateFieldGroup = async (
    fieldUri: string | undefined,
    groupUri: string | undefined,
    order: string,
    shouldRemoveMappedLayout?: boolean,
  ) => {
    if (!fieldUri) return;

    const field = sectionFields?.find((f) => f.uri === fieldUri);
    if (!field) return;

    try {
      setIsUpdating(fieldUri, true);
      await mergeUpdateField({
        current: field.values,
        incoming: {
          groupUri,
          order,
          withGroupUpdate: true,
        },
        shouldRemoveMappedLayout,
      });
    } catch (error) {
      console.error("Error updating field group: ", error);
    } finally {
      setIsUpdating(fieldUri, false);
    }
  };

  return {
    addMappedFields,
    addGroup,
    updateGroup,
    changeGroupOrder,
    deleteGroup,
    addField,
    updateField,
    changeFieldOrder,
    deleteField,
    updateSection,
    updateFormSection,
    updateAllSections,
    newItemUri,
    setNewItemUri,
    updatingItems,
    updateFieldGroup,
    updateAIUserFeedback,
    mergeUpdateField,
    mergeUpdateGroup,
  };
};
