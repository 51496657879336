import { Control, Controller, useController } from "react-hook-form";

import { loadLocale } from "@smart/itops-locale-dom";
import {
  FieldList,
  RadioButtonOptionItem,
  RadioButtons,
  Select,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";
import { isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { CreatingFormOptions, EditingFormInfo } from "../form";
import { UploadQuestions } from "../upload";

type DataItem = { category: string; name: string };

const groupByCategory = (data: DataItem[]) =>
  Object.entries(
    data.reduce(
      (acc, { category, ...rest }) => {
        (acc[category] ||= []).push({ category, ...rest });
        return acc;
      },
      {} as Record<string, DataItem[]>,
    ),
  ).map(([heading, items]) => ({ heading, items }));

type FormFieldsListProps = {
  control: Control<CreatingFormOptions> | Control<EditingFormInfo>;
  formName?: boolean;
  fileUpload?: boolean;
  locations?: string[];
  matterTypeOptions?: { category: string; name: string }[];
  formCategoryOptions?: {
    value: "lead" | "matter";
    label: string;
  }[];
  creationTypeOptions?: RadioButtonOptionItem[];
  aiWordSupport?: boolean;
};
export const FormFieldsList = ({
  control: ctrl,
  formName,
  fileUpload,
  locations,
  matterTypeOptions,
  formCategoryOptions,
  creationTypeOptions,
  aiWordSupport,
}: FormFieldsListProps) => {
  const control = ctrl as Control<CreatingFormOptions | EditingFormInfo>;
  const { terms } = loadLocale();

  const { field: locationsField } = useController({
    control,
    name: "locations",
  });
  const { field: matterTypeGroupsField } = useController({
    control,
    name: "matterTypeGroups",
  });
  const { field: formCategoryField } = useController({
    control,
    name: "formCategory",
  });
  const supportedFileExtensions = [".png", ".jpg", ".jpeg", ".pdf"];
  if (aiWordSupport) {
    supportedFileExtensions.push(".doc", ".docx");
  }

  return (
    <FieldList>
      {!!formCategoryOptions && (
        <Controller
          control={control}
          name="formCategory"
          render={({ field, fieldState }) => (
            <RadioButtons
              id={field.name}
              label="Form type"
              error={!!fieldState.error}
              message={fieldState.error?.message}
              options={formCategoryOptions}
              mandatory
              onChange={(value) => {
                matterTypeGroupsField.onChange([]);
                formCategoryField.onChange(value);
              }}
              value={field.value}
            />
          )}
        />
      )}
      {!!formName && (
        <Controller
          control={control}
          name="formName"
          render={({ field, fieldState }) => (
            <TextFieldInput
              id={field.name}
              label="Form name"
              message={
                fieldState.error?.message ??
                "Enter a unique name for your new form"
              }
              error={!!fieldState.error}
              value={field.value}
              onChange={field.onChange}
              mandatory
            />
          )}
        />
      )}
      {!!locations && (
        <Controller
          control={control}
          name="locations"
          render={({ field, fieldState }) => (
            <Select
              id={field.name}
              label={terms.state.title}
              options={(locations || []).map((c) => ({
                label: c,
                value: c,
              }))}
              error={!!fieldState.error}
              message={fieldState.error?.message}
              value={field.value}
              onChange={(newLocations) => {
                const shouldRemove =
                  !newLocations?.length ||
                  locationsField.value.length < newLocations.length;
                if (shouldRemove) {
                  matterTypeGroupsField.onChange([]);
                } else {
                  matterTypeGroupsField.onChange(
                    matterTypeGroupsField.value
                      .map((group) => {
                        const updatedTypes = group.matterTypes.filter(
                          (matterType) =>
                            newLocations.includes(matterType.location),
                        );
                        return updatedTypes.length
                          ? { ...group, matterTypes: updatedTypes }
                          : undefined;
                      })
                      .filter(isNotNullOrUndefined),
                  );
                }
                field.onChange(newLocations);
              }}
              mandatory
              isMultiple
            />
          )}
        />
      )}
      {!!matterTypeOptions && (
        <Controller
          control={control}
          name="matterTypeGroups"
          render={({ field, fieldState }) => (
            <Select
              id={field.name}
              label="Matter types"
              error={!!fieldState.error}
              message={fieldState.error?.message}
              options={groupByCategory(matterTypeOptions || []).map((mto) => ({
                heading: mto.heading,
                items: mto.items.map((i) => ({ label: i.name, value: i })),
              }))}
              value={field.value}
              onChange={(v) => field.onChange(v)}
              valueComparer={(currentValue, valueToCompare) =>
                currentValue.name === valueToCompare.name &&
                currentValue.category === valueToCompare.category
              }
              isMultiple
              mandatory
              empty="No matter types available"
            />
          )}
        />
      )}
      {!!creationTypeOptions && (
        <Controller
          control={control}
          name="creationType"
          render={({ field, fieldState }) => (
            <RadioButtons
              id={field.name}
              label="Form building options"
              error={!!fieldState.error}
              message={fieldState.error?.message}
              options={creationTypeOptions}
              mandatory
              {...field}
            />
          )}
        />
      )}
      {!!fileUpload && (
        <Controller
          control={control}
          name="filePath"
          render={({ field, fieldState }) => (
            <UploadQuestions
              id={field.name}
              label="Upload existing document"
              message={fieldState.error?.message}
              name={field.name}
              onChange={field.onChange}
              error={!!fieldState.error}
              supportedFileExtensions={supportedFileExtensions}
              mandatory
            />
          )}
        />
      )}
    </FieldList>
  );
};
